import React from "react"
import styled from "styled-components"

const Keywords = ({ id, keywords }) => {
    const KeywordsWrapper = styled.div`
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
    `
    return(
        <KeywordsWrapper id={id} className="sr-only">
            {keywords.join(', ')}
        </KeywordsWrapper>
    )
}

export default Keywords