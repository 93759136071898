import React from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { theme } from "@common/src/theme/agencyModern";
import { ResetCSS } from "@common/src/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "../components/styling-wrappers/agencyModern.style";
import { DrawerProvider } from "@common/src/contexts/DrawerContext";
import Navbar from "../components/public-components/Navbar";
import Banner from "../components/produk-kami-components/Banner";
import ProdukList from "../components/produk-kami-components/ProdukList";
import NutrigenmeDetails from "../components/produk-kami-components/ProdukDetail/NutrigenmeDetails";
import SkingenmeDetails from "../components/produk-kami-components/ProdukDetail/SkingenmeDetails";
import FitgenmeDetails from "../components/produk-kami-components/ProdukDetail/NutrigenmeLifeDetails";
import Footer from "../components/public-components/Footer";
import Seo from "../components/seo";
import PrimeGenme from "../components/produk-kami-components/ProdukDetail/PrimegenmeDetails";
import KiddygenmeDetails from "../components/produk-kami-components/ProdukDetail/KiddygenmeDetails";
import PopupImage from "../components/PopupImage";
import ButtonContact from "../components/index-components/ButtonContact";
import Keywords from "../components/Keywords";
import Layout from "../components/layout";

const produkPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Seo
        title="Produk"
        description="Produk unggulan kami yaitu NutriGENME, SkinGENME, FitGENME Diet, dan FitGENME Sport"
        keywords={[
          "tes gen",
          "tes dna",
          "dna test",
          "genetic test",
          "tes genetik",
          "nutrigenomics",
          "nutrigenomik",
          "pemeriksaan genetik",
          "nutrigenme",
          "genme",
          "kalbe genetics",
          "kalbe genetik",
          "genme kalbe",
          "genme by kalbe",
          "pemeriksaan air liur",
          "microarray",
          "personalized nutrition",
          "personalized diet",
          "personalized lifestyle",
          "healthy lifestyle",
          "based on dna",
          "based on genetics",
        ]}
      />
      <ResetCSS />
      <GlobalStyle />
      <Layout>
        <PopupImage/>
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar origin="Produk" />
            </DrawerProvider>
          </Sticky>
          <Banner />
          <ProdukList />
          <FitgenmeDetails />
          {/* <NutrigenmeDetails /> */}
          {/* <SkingenmeDetails /> */}

          <PrimeGenme />
          <KiddygenmeDetails />
          <Footer />
          <ButtonContact/>
          <Keywords
            id={"keywords"}
            keywords={[
              "tes gen",
              "tes dna",
              "dna test",
              "genetic test",
              "tes genetik",
              "nutrigenomics",
              "nutrigenomik",
              "pemeriksaan genetik",
              "nutrigenme",
              "genme",
              "kalbe genetics",
              "kalbe genetik",
              "genme kalbe",
              "genme by kalbe",
              "pemeriksaan air liur",
              "microarray",
              "personalized nutrition",
              "personalized diet",
              "personalized lifestyle",
              "healthy lifestyle",
              "based on dna",
              "based on genetics",
            ]}
          />
        </ContentWrapper>
      </Layout>
    </ThemeProvider>
  );
};

export default produkPage;
