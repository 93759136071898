import React from "react";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Container from "@common/src/components/UI/Container";
import MarkdownText from "@common/src/components/MarkdownText";
import Heading from "@common/src/components/Heading";
import Image from "@common/src/components/Image";
import List from "@common/src/components/List";

import SectionWrapper, {
  Section,
  Content,
  Illustration,
  ListGroup,
} from "../produkDetailRight.style";

const WorkHard = () => {
  const Data = useStaticQuery(graphql`
    query {
      Tick: file(relativePath: { eq: "image/produkKami/tick.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contentfulProdukKami(title: { eq: "NutriGEN-ME LIFE" }) {
        childContentfulProdukKamiProdukListJsonNode {
          childContentfulProdukKamiProdukListJsonNodeJson {
            details {
              title
              id
            }
          }
        }
        image {
          gatsbyImageData
        }
        contentDescription{
          raw
        }
      } 
    }
  `);

  const fitgenmeList =
    Data.contentfulProdukKami?.childContentfulProdukKamiProdukListJsonNode
      .childContentfulProdukKamiProdukListJsonNodeJson.details;
  const produkimg = Data.contentfulProdukKami?.image.gatsbyImageData;
  const produkContent = Data.contentfulProdukKami?.contentDescription;
  // console.log(Data.contentfulProdukKami);

  if(!fitgenmeList) return null
  return (
    <SectionWrapper id="produk-nutrigenmelife">
      <Container>
        <Fade up delay={100}>
          <Section>
            <Content>
              <Heading as="h2" content="NutriGenme Life" />
              <MarkdownText content={produkContent}/>
              <ListGroup>
                {fitgenmeList.map((item) => {
                  return (
                    <List
                      className="list-item"
                      key={item.id}
                      text={item.title}
                      icon={
                        <Image
                          fluid={
                            (Data.Tick !== null) | undefined
                              ? Data.Tick.childImageSharp.fluid
                              : {}
                          }
                          src={Data.Tick.childImageSharp.fluid.src}
                          objectFit="contain"
                          alt="Tick Icon"
                        />
                      }
                    />
                  );
                })}
              </ListGroup>
            </Content>
            <Illustration>
              <GatsbyImage
                // image={getImage(produkimg)}
                image={produkimg}
                alt="NutriGenme"
                key="NutriGenme"
              />
            </Illustration>
          </Section>
        </Fade>
      </Container>
    </SectionWrapper>
  );
};

export default WorkHard;
